import React from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({ customStyle }) => {
    return (
        <Accordion className={`rn-accordion-style ${customStyle}`} defaultActiveKey="0">
            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Why would my business need a consultant?
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>Businesses of all sizes can benefit from the expertise and objectivity a consultant brings. We can help you identify areas for improvement, develop strategic plans, and implement solutions you may not have considered. We act as a sounding board for your ideas and provide a fresh perspective to overcome challenges. </Card.Body>
                </Accordion.Collapse>

                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="1">
                            How can you ensure a good fit between your consulting services and my business needs?
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                        <Card.Body>We begin by scheduling a free consultation to understand your specific goals, challenges, and industry. This allows us to tailor our services to directly address your needs and ensure a strong working partnership.</Card.Body>
                    </Accordion.Collapse>
                </Card>

                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                            What are your areas of expertise?
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                        <Card.Body>We offer Business Formation & Strategy, Risk Management, Supply Chain Management, Marketing & Communication, Mergers & Acquisitions, and Business Process Outsourcing. We also have a network of trusted partners with experience in other areas to provide a comprehensive solution for your business.</Card.Body>
                    </Accordion.Collapse>
                </Card>



            </Card>





            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                        How long does a typical consulting project last?
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                    <Card.Body>The duration of a project depends on the scope and complexity of your needs. We can offer short-term engagements for specific tasks or longer-term projects for comprehensive business transformation.</Card.Body>
                </Accordion.Collapse>

                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="4">
                            How do you structure your fees?
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="4">
                        <Card.Body>We offer flexible fee structures to meet your budgeting needs. We can discuss hourly rates, project-based fees, or retainer agreements depending on the project scope. We believe in transparent pricing and will provide a clear cost estimate before starting any work.</Card.Body>
                    </Accordion.Collapse>
                </Card>

                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="5">
                            What is your process for working with clients?
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="5">
                        <Card.Body>We follow a collaborative approach, keeping you informed throughout the project. This typically involves initial consultations, data analysis, strategy development, implementation, and ongoing support.</Card.Body>
                    </Accordion.Collapse>
                </Card>



            </Card>







            <Card>
                <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="6">
                        Can you share some examples of your past successes?
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="6">
                    <Card.Body>Absolutely! We can provide case studies or testimonials from satisfied clients on our website or during a consultation. These examples will showcase the positive impact we've had on businesses similar to yours.</Card.Body>
                </Accordion.Collapse>

                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="7">
                            What are your client's biggest challenges, and how do you help them overcome them?
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="7">
                        <Card.Body>Common challenges include growth plateaus, increasing profitability, improving operational efficiency, and navigating industry changes. We use a data-driven approach to identify the root cause of problems and develop customized solutions to achieve sustainable success.</Card.Body>
                    </Accordion.Collapse>
                </Card>

                <Card>
                    <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey="8">
                            How do you measure the success of your consulting engagements?
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="8">
                        <Card.Body>Success is defined by achieving your specific goals. We track key performance indicators (KPIs) throughout the project to demonstrate the positive impact of our recommendations.</Card.Body>
                    </Accordion.Collapse>
                </Card>

            </Card>
            
        </Accordion>
    )
}
export default AccordionOne

