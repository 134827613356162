import React from 'react';
import Typed from 'react-typed';
import {Link} from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import AccordionOne from '../accordion/AccordionOne';

const mission = () => {
    return (
        <div className="rn-company-mission-are rn-section-gap">
            <div className="container">
                <div className="row row--30">
                    <div className="col-lg-6">
                        <div className="mission-title">
                            <h2 className="title">Empowering Businesses with  <br /> {" "}
                                <Typed className="theme-gradient"
                                    strings={[
                                        "Comprehensive",
                                        "Consulting",
                                        "Solutions.",
                                    ]}
                                    typeSpeed={80}
                                    backSpeed={5}
                                    backDelay={1000}
                                    loop
                                />
                            </h2>
                            <p>At D.A. Business Consulting, we empower businesses to reach their full potential through expert consulting services. Our dedicated team provides tailored solutions to optimize efficiency, maximize profitability, and foster sustainable growth. Whether you're a startup navigating challenges or an established business aiming to innovate, our strategic insights and industry expertise will guide you toward overcoming obstacles and achieving lasting success.</p>

                            <h5>Mission Statement:</h5>

                            <p>To empower businesses of all sizes, particularly startups and medium-sized enterprises, with expert consulting services that optimize efficiency, maximize profitability, and foster sustainable growth.</p>
                            
                            <h5>Vision Statement:</h5>
                            
                            <p>To be the trusted partner and catalyst for success, known for our tailored consulting solutions that enable businesses to innovate, overcome challenges, and achieve enduring prosperity in a dynamic market environment.</p>


                            <h5>Core Values:</h5>

                            <ul>
                                <li><h6>Excellence</h6> 
                                    <p>We strive for outstanding performance in every facet of our service delivery, consistently pushing boundaries to achieve exceptional outcomes.</p>
                                </li>

                                <li><h6>Integrity</h6>
                                    <p>We uphold unwavering ethical standards, fostering trust through transparency, honesty, and accountability in all our interactions.</p>
                                </li>

                                <li><h6>Collaboration</h6>
                                    <p>We embrace diverse perspectives and foster a culture of teamwork, harnessing collective creativity to innovate and solve complex challenges.</p>
                                </li>

                                <li><h6>Client-Centric Innovation</h6>
                                    <p>Our commitment to understanding and exceeding client expectations drives us to develop bespoke solutions that inspire growth and success.</p>
                                </li>
                            </ul>
                            
                            <h5>Transforming challenges into opportunities with innovative and client-centric consulting solutions.</h5>

                        </div>
                    </div>
                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                        <h2 style={{textAlign: 'center'}} className="title">FAQs <br /> {" "}
                        {/* <Typed className="theme-gradient"
                            strings={[
                                "How do I get started?",
                                "Where do I start?",
                                "What is D.A. Business Consulting?",
                            ]}
                            typeSpeed={80}
                            backSpeed={5}
                            backDelay={1000}
                            loop
                            /> */}
                        </h2>
                        <AccordionOne customStyle="" />
                    </div>
                </div>
            </div>                        
        </div>
    )
}
export default mission;
