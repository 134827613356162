import React from 'react';
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import IntakeForm from './intakeForm';

const IntakeOne = () => {
    return (
        <>
            <div className="row mt--40 row--15">
                <div className="col-lg-7">
                    <IntakeForm formStyle="contact-form-1" />
                </div>
            </div>
        </>
    )
}
export default IntakeOne;