import React from 'react';
import Typed from 'react-typed';

const AboutOne = () => {
    return (
        <div className="rwt-about-area rn-section-gap">
            <div className="container">
                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" style={{borderRadius: 10}} src="https://firebasestorage.googleapis.com/v0/b/dabconsulting-ae8bb.appspot.com/o/CEO%20Pic.png?alt=media&token=8a78660e-8ba9-4d45-8f75-5d37c7f7fbc0" alt="About Images" />
                        </div>
                    </div>

                    <div className="col-lg-7 mt_md--40 mt_sm--40">
                        <div className="content">
                            <div className="section-title">
                                <h2 className="title">Unique Business <br /> {" "}
                                    <Typed className="theme-gradient"
                                        strings={[
                                            "Consulting.",
                                            "Development.",
                                            "Marketing.",
                                        ]}
                                        typeSpeed={80}
                                        backSpeed={5}
                                        backDelay={1000}
                                        loop
                                    />
                                </h2>
                                <p>Dear Esteemed Clients,
                                    <br /><br />
                                    The burgeoning landscapes of developed and developing economies hold a transformative power that excites me deeply—having witnessed firsthand the challenges and triumphs of navigating business in such environments. At D.A. Business Consulting, we help companies navigate these environments and achieve sustainable growth.   We are passionate about fostering growth that transcends individual businesses and companies. It's about building ecosystems that empower not just businesses, but communities.
                                    <br /><br />
                                    At D.A. Business Consulting, we go beyond mere optimization. We understand the unique hurdles faced by businesses in developed and developing nations. My own experience, coupled with my MBA specializing in International Business with a focus on emerging markets, allows me to see the bigger picture. We don't just help you thrive, we help you become a catalyst for progress.
                                    <br /><br />
                                    Our mission is to co-create a ripple effect of success. We don't offer a one-size-fits-all approach. Instead, we collaborate with you to develop innovative strategies that penetrate new markets, build operational efficiency, and leverage technology in ways that address local needs and infrastructure realities. In this dynamic world, agility and a deep understanding of context are paramount. With D.A. by your side, you'll not only weather uncertainty but become a leader in shaping a more prosperous future.
                                    <br /><br />
                                    We invite you to be a co-architect of change. Together, let's redefine success by fostering sustainable growth that elevates businesses, empowers communities, and transforms the economic narrative of developed and developing nations. This isn't just about your company's success – it's about creating a legacy of positive impact.
                                    <br /><br />
                                    With unwavering commitment,
                                    <br /><br />
                                    <p style={{fontWeight: 700}}>Daineroy Allen CEO, DA Business Consulting</p>
                                    </p>
                                {/* <p>A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences. A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences </p> */}
                                {/* <div className="read-more-btn mt--40">
                                    <a className="btn-default" href="#"><span>More About Us</span></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default AboutOne
