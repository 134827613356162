// Import the functions you need from the SDKs you need
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBPba58MlQenXy4pcSsRO4AQRBdDEbY_7w",
    authDomain: "dabconsulting-ae8bb.firebaseapp.com",
    projectId: "dabconsulting-ae8bb",
    storageBucket: "dabconsulting-ae8bb.appspot.com",
    messagingSenderId: "169225129703",
    appId: "1:169225129703:web:9a636c18b7a9fa86ad1c42",
    measurementId: "G-PTBLYGCSCL"
};


export default firebaseConfig;