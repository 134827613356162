import React from 'react'
import SEO from "../common/SEO";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderOne from '../common/header/HeaderOne';
import FooterFour from '../common/footer/FooterFour';
import BrandThree from '../elements/brand/BrandThree';
import AboutFour from '../elements/about/AboutFour';
import ServiceOne from "../elements/service/ServiceOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamFour from "../elements/team/TeamFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import Separator from "../elements/separator/Separator";
import HeaderTwo from '../common/header/HeaderTwo';
import AboutOne from '../elements/about/AboutOne';
import Mission from "../elements/about/Mission";

const AboutUs = () => {
    return (
        <>
            <SEO title="About Us" />
            <main className="page-wrapper">
                {/* <HeaderTopNews /> */}
                <HeaderTwo btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Mission Area  */}
                <Mission />
                {/* End of mission Area */}

                {/* Start Slider Area  */}
                <div style={{marginTop: '-12%'}} className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        {/* <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign="text-center"
                                    radiusRounded=""
                                    subtitle="Message from our CEO"
                                    title="Daineroy Allen"
                                    description="Chief Executive Officer DA Business Consulting"
                                />
                            </div>
                        </div> */}
                        <AboutOne />
                    </div>
                </div>
                {/* End Slider Area  */}


                <FooterFour />
            </main>
        </>
    )
}

export default AboutUs;
