import React, { useEffect, useState } from 'react'
import SEO from "../common/SEO";
import { Link } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
import HeaderTopNews from '../common/header/HeaderTopNews';
import HeaderTwo from '../common/header/HeaderTwo';
import FooterThree from '../common/footer/FooterThree';
import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import TimelineTwo from '../elements/timeline/TimelineTwo';
import TabOne from '../elements/tab/TabOne';
import AboutThree from '../elements/about/AboutThree';
import PricingThree from '../elements/pricing/PricingThree';
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from '../data/blog/BlogList.json';
var BlogListData = BlogClassicData.slice(0, 3);
import firebase from '../config/firebase';
import { doc, onSnapshot, setDoc, addDoc, query, collection, getDocs, where, serverTimestamp, updateDoc } from "firebase/firestore";
import { Button, Toast } from 'react-bootstrap';
import FooterFour from '../common/footer/FooterFour';



const Business = () => {
    const [data, setData] = useState({});

    const [titleOne, setTitleOne] = useState('');
    const [titleTwo, setTitleTwo] = useState('');
    const [titleThree, setTitleThree] = useState('');
    const [titleFour, setTitleFour] = useState('');
    const [titleFive, setTitleFive] = useState('');
    const [titleSix, setTitleSix] = useState('');
    const [titleSeven, setTitleSeven] = useState('');
    const [titleEight, setTitleEight] = useState('');
    const [titleNine, setTitleNine] = useState('');
    const [titleTen, setTitleTen] = useState('');
    const [titleEleven, setTitleEleven] = useState('');
    const [titleTwelve, setTitleTwelve] = useState('');
    const [titleThirteen, setTitleThirteen] = useState('');
    const [titleFourteen, setTitleFourteen] = useState('');
    const [titleFifteen, setTitleFifteen] = useState('');

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const q = query(collection(firebase.firestore, "controls"), where("id", "==", 'JaD1sW2w1Yb4gTcbYDRt'));
            const querySnapshot = await getDocs(q);
            const dataFields = querySnapshot.docs.map(doc => doc.data());
            const val = dataFields[0];
            setData(dataFields[0]);
            console.log(dataFields[0]);
            setTitleOne(val?.landingPage?.title);
            setTitleTwo(val?.landingPage?.subTitle);
            setTitleThree(val?.landingPage?.section1?.title);
            setTitleFour(val?.landingPage?.section1?.subTitle);

            setTitleFive(val?.landingPage?.section3?.title);
            setTitleSix(val?.landingPage?.section3?.subTitle);
            setTitleSeven(val?.landingPage?.section4?.title);
            setTitleEight(val?.landingPage?.section4?.subTitle);
            setTitleNine(val?.landingPage?.section5?.title);
            setTitleTen(val?.landingPage?.section5?.subTitle);
            setTitleEleven(val?.landingPage?.section6?.title);
            setTitleTwelve(val?.landingPage?.section6?.subTitle);
            setTitleThirteen(val?.landingPage?.section7?.title);
            setTitleFourteen(val?.landingPage?.section7?.subTitle);
            // setTitleFifteen(val?.landingPage?.section13?.title);

        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle error (e.g., display error message, retry logic)
        }
    };

    const updateFields = async () => {
        const docRef = doc(collection(firebase.firestore, "controls"), 'JaD1sW2w1Yb4gTcbYDRt');
        const landingPage = {
                title: titleOne,
                subTitle: titleTwo,
                section1: { title: titleThree, subTitle: titleFour },
                section3: { title: titleFive, subTitle: titleSix },
                section4: { title: titleSeven, subTitle: titleEight },
                section5: { title: titleNine, subTitle: '' },
                section6: { title: '', subTitle: '' },
                section7: { title: '', subTitle: '' },
                section8: { title: '' }
            };
        console.log(landingPage);

        try {
            await updateDoc(docRef, {
                landingPage: landingPage
            });
            console.log("Document updated successfully");

        } catch (e) {
            console.error("Error updating document: ", e);
        }
    };

    return (
        <>
            {/* <SEO title="Business" /> */}
            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small" HeaderSTyle="header-not-transparent" />
                <div>

                </div>
                <Separator />

                <div style={{ marginLeft: '10%', marginRight: '10%', marginTop: '5%' }}>
                    <div className="row">
                        <div className="col-md-12">
                            <h2>Dashboard</h2>
                            <h5>Landing Page</h5>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <input
                                        type="text"
                                        name="headerTitle"
                                        value={titleOne}
                                        onChange={(e) => setTitleOne(e.target.value)}
                                        placeholder="Header Title"
                                        className="form-control"
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <input
                                        type="text"
                                        name="phone"
                                        value={titleTwo}
                                        onChange={(e) => setTitleTwo(e.target.value)}
                                        placeholder="Contact Phone Number"
                                        className="form-control"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <input
                                        type="text"
                                        name="section1"
                                        value={titleThree}
                                        onChange={(e) => setTitleThree(e.target.value)}
                                        placeholder="Section 1 Sub Title"
                                        className="form-control"
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <input
                                        type="text"
                                        name="section2"
                                        value={titleFour}
                                        onChange={(e) => setTitleFour(e.target.value)}
                                        placeholder="Section 2 Sub Title"
                                        className="form-control"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <input
                                        type="text"
                                        name="section3"
                                        value={titleFive}
                                        onChange={(e) => setTitleFive(e.target.value)}
                                        placeholder="Section 3 Sub Title"
                                        className="form-control"
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <input
                                        type="text"
                                        name="section4"
                                        value={titleSix}
                                        onChange={(e) => setTitleSix(e.target.value)}
                                        placeholder="Section 4 Sub Title"
                                        className="form-control"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <input
                                        type="text"
                                        name="section5"
                                        value={titleSeven}
                                        onChange={(e) => setTitleSeven(e.target.value)}
                                        placeholder="Section 5 Sub Title"
                                        className="form-control"
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <input
                                        type="text"
                                        name="section6"
                                        value={titleEight}
                                        onChange={(e) => setTitleEight(e.target.value)}
                                        placeholder="Section 6 Sub Title"
                                        className="form-control"
                                        required
                                    />
                                </div>
                            </div>



                            <div className="row">
                                <div className="form-group col-md-6">
                                    <input
                                        type="text"
                                        name="section7"
                                        value={titleNine}
                                        onChange={(e) => setTitleNine(e.target.value)}
                                        placeholder="Section 7 Sub Title"
                                        className="form-control"
                                        required
                                    />
                                </div>
                                {/* <div className="form-group col-md-6">
                                    <input
                                        type="text"
                                        name="section8"
                                        value={titleTen}
                                        onChange={(e) => setTitleTen(e.target.value)}
                                        placeholder="Section 8 Sub Title"
                                        className="form-control"
                                        required
                                    />
                                </div> */}
                            </div>


                            {/* <div className="row">
                                <div className="form-group col-md-6">
                                    <input
                                        type="text"
                                        name="section9"
                                        value={titleEleven}
                                        onChange={(e) => setTitleEleven(e.target.value)}
                                        placeholder="Section 9 Sub Title"
                                        className="form-control"
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <input
                                        type="text"
                                        name="section10"
                                        value={titleTwelve}
                                        onChange={(e) => setTitleTwelve(e.target.value)}
                                        placeholder="Section 10 Sub Title"
                                        className="form-control"
                                        required
                                    />
                                </div>
                            </div> */}


                            {/* <div className="row">
                                <div className="form-group col-md-6">
                                    <input
                                        type="text"
                                        name="section11"
                                        value={titleThirteen}
                                        onChange={(e) => setTitleThirteen(e.target.value)}
                                        placeholder="Section 11 Sub Title"
                                        className="form-control"
                                        required
                                    />
                                </div>
                                <div className="form-group col-md-6">
                                    <input
                                        type="text"
                                        name="section12"
                                        value={titleFourteen}
                                        onChange={(e) => setTitleFourteen(e.target.value)}
                                        placeholder="Section 12 Sub Title"
                                        className="form-control"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group col-md-6">
                                    <input
                                        type="text"
                                        name="section13"
                                        value={titleFifteen}
                                        onChange={(e) => setTitleFifteen(e.target.value)}
                                        placeholder="Section 13 Sub Title"
                                        className="form-control"
                                        required
                                    />
                                </div>
                            </div> */}
                        </div>
                    </div>

                    <div onClick={updateFields} style={{ marginBottom: '1%' }} className="button-group mt-10 mb-10">
                        <a className="btn-default">Update</a>
                    </div>

                </div>

                <FooterFour />
            </main>

        </>
    )
}
export default Business;