import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";

const Logo = ({image, image2}) => {
    return(
        <div style={{width: 150, height: 60, marginTop: '-3.6%'}} className="">
            <Link to={process.env.PUBLIC_URL + "/"}>
                <img className="logo-light img-fluid" src={process.env.PUBLIC_URL + image} alt="Corporate Logo" />
                <img className="logo-dark img-fluid" src={process.env.PUBLIC_URL + image2} alt="Corporate Logo" />
            </Link>
        </div>
    )
}
Logo.propTypes = {
    image: PropTypes.string
};

export default Logo;
