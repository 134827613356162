import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import HeaderOne from "../../common/header/HeaderOne";
import FooterOne from "../../common/footer/FooterOne";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ServiceOne from "./ServiceOne";
import ServiceTwo from "./ServiceTwo";
import ServiceThree from "./ServiceThree";
import ServiceFour from "./ServiceFour";
import ServiceFive from "./ServiceFive";
import Separator from "../separator/Separator";
import HeaderTwo from '../../common/header/HeaderTwo';
import PortfolioOne from '../portfolio/PortfolioOne';

const Service = () => {
    return (
        <>

            <SEO title="Services" />
            <HeaderTwo btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />
            <Layout>
                {/* <BreadcrumbOne 
                    title="The Easiest Way To Create Website <br /> Quick Copy & Make site."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Service"
                /> */}
                {/* <div className="main-content"> */}

                    <div className="rwt-portfolio-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign="text-center"
                                        radiusRounded=""
                                        subtitle="Services"
                                        title="Experience the Difference"
                                        description="Our services are tailored for client success"
                                    />
                                </div>
                            </div>
                            <PortfolioOne Column="col-lg-3 col-md-3 col-sm-3 col-12 mt--30 portfolio no-overlay" />
                        </div>
                    </div>
                    
                {/* </div> */}

            </Layout>
            
        </>
    )
}

export default Service;
